@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bungee+Spice&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Wellfleet&display=swap');
body {
  margin: 0;
  overflow-x: hidden;
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.serviceHead{
    font-family: 'Wellfleet', monospace;
}

.customButton{
  background-color: #00247E !important;
  border: 1px solid #00247E;
  transition: all 0.3s ease;
}
.customButton:hover{
  background-color: #ffffff !important;
  color: #00247E !important;
}

.serviceDescription{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  
}