.bgContainer{
    background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("https://i.ibb.co/TkggTYm/electrical-plumbing.jpg");
    /* height: 100vh; */
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 40px;
}
.counter{
    font-size: 100px;
    font-weight: 700;
    color: white;
}