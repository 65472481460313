.swiper, swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
    display: block;
}
.swiper-pagination {
    position: relative !important;
    margin-top: 10px;
    text-align: center;
    transition: .3s opacity;
    transform: translate3d(0,0,0);
    z-index: 10;
}
.sliderStyle{
    height: 80vh;
    display: flex;
    height: 80vh;
    align-items: center;
    justify-content: center;
    font-weight: 700;
}
