body {
	align-items: center;
	justify-content: center;
}

:root {
	--animationSpeed: 40s;
}

@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-250px * 7)); }
}

.slider {
	/* height: 100px; */
	margin: auto;
	overflow: hidden;
	position: relative;
	width: auto;
	
	.slide-track {
		animation: scroll var(--animationSpeed) linear infinite;
		display: flex;
		align-items: center;
		gap:25px
		/* width: calc(250px * 14); */
	}
	
	
}

.slider.second-section {
    /* height: 100px; */
    margin: auto;
    overflow: hidden;
    position: relative;
    width: auto;

    .slide-track {
        animation: scroll-right var(--animationSpeed) linear infinite;
        display: flex;
        align-items: center;
        gap: 25px;
        /* width: calc(250px * 14); */
    }
}



.swiper-wrapper{
    display: flex;
    align-items: center;
}

@keyframes scroll-right {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(250px * 7));
    }
}

