.ourServiceText{
   
    margin-top: 15px;
    font-weight: 700;
    color: white;
}
.serviceBg {
    position: relative;
    padding-top: 40px;
    color: white;
  }
  
  .serviceBg::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0; /* Align the background image to the right */
    width: 100%; /* Set the width of the background image */
    height: 100%;
    background-image:  linear-gradient(to left, rgba(0, 32, 96, 0.7), rgba(0, 32, 96, 0)), url(https://i.ibb.co/BTpfFsK/whatis-I2-I.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-attachment: fixed;
    z-index: -1;
  }
  
.textContainer{
    background-color: #006AAC;
    /* height: auto; */
    width: 300px;
    padding: 10px;
    height: 80px;
    border-radius: 10px;
    transition: 0.3s ease-in-out;
}
.textContainer:hover{
    background-color: #359ee0;
    cursor: pointer;
}


.textOne{
    font-weight: 700;
    color: #fff;
}
.servicePara{
    height: 120px;
   
    background-color: white;
    color: #006AAC;
    /* width: 400px; */
    display: flex;
    align-items: center;
    /* justify-content: end; */
    /* font-size: 22px; */
    padding: 10px;
    border-radius: 10px;
    /* text-align: right; */
    transition: 0.3s ease-in-out;
}
.servicePara:hover{
    background-color: rgb(235, 233, 233);
    cursor: pointer;
}

.servicePara p{
    /* margin-top: 5px; */
    color: #006AAC;
}
