.flip-card {
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #002060;
  /* color: #000; */
}

.flip-card-back {
  background-color: #2980b9;
  color: #fff;
  transform: rotateY(180deg);
}

.flip-card h2 {
  text-align: center;
  margin: 0;
  padding: 20px;
}

.flip-card p {
  text-align: center;
  margin: 0;
  padding: 20px;
}

/* Additional styles for responsiveness */
@media (max-width: 768px) {
  .flip-card {
    width: 90%;
  }

  .flip-card h2 {
    font-size: 25px;
  }

  .flip-card p {
    font-size: 18px;
  }
}
